






































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'
import { formatDateTimeString } from '@/utils/dateUtils'
import PdfViewer from '@/components/PdfViewer.vue'
import i18n from '@/i18n'

@Component({
  layout: 'unAuthenticated',
  components: { PdfViewer },
})
export default class List extends Vue {
  private data: any = {}
  private loading = true
  private appendSitePrefix = appendSitePrefix

  private reportTypeNone = 'NONE'
  private reportTypeEontyre = 'EONTYRE'
  private reportTypeDevice = 'DEVICE'

  private reportType = null
  private readingPdfs = []
  private tyrePdfs = []

  private tyreReadingImages = []
  private tyreImages = []
  private readingImageCarouselModel = [0, 0, 0, 0]
  private tyreImageCarouselModel = [0, 0, 0, 0]

  private fullImages = []
  private fullImageCarouselModel = 0
  private fullImageDialog = false

  private fullImageTyre = {
    index: null,
    placement: null,
  }

  private shopLogo = null
  private shopAddress = {
    name: null,
    address: null,
    zipcode: null,
    city: null,
    phone: null,
    email: null,
  }

  private tab = -1

  created() {
    this.loading = true

    this.$axios
      .get('/v4/tread-depth-reading/' + this.$route.params.token)
      .then((response) => {
        const reading = response.data.reading
        this.shopAddress = response.data.shopAddress
        this.shopLogo = response.data.shopLogo
        this.reportType = response.data.reportType
        if (this.reportType === this.reportTypeDevice) {
          this.tab = 0
        }

        i18n.setLanguage(response.data.language.split('_')[0])

        this.readingPdfs = reading.media.filter((media) => media.type === 'ReportPdf')

        const order = ['ReadingImage', 'Unknown']
        const map = new Map()
        order.forEach((x, i) => map.set(x, i))

        for (let i = 0; i < reading.tyres.length; i++) {
          reading.tyres[i].index = i

          this.tyreReadingImages[i] = reading.tyres[i].media.filter(
            (media) => media.type !== 'TyreImage' && media.type !== 'ReportPdf',
          )
          // Order images based on type, so we have ReadingImage first and then the rest
          this.tyreReadingImages[i].sort((x, y) => map.get(x.type) - map.get(y.type))
          this.tyreImages[i] = reading.tyres[i].media.filter((media) => media.type === 'TyreImage')
          this.tyrePdfs[i] = reading.tyres[i].media.filter((media) => media.type === 'ReportPdf')
        }
        this.data = reading
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getPlacementText(placement) {
    switch (placement) {
      case 'LeftFront':
        return this.$t('Left front')
      case 'RightFront':
        return this.$t('Right front')
      case 'LeftRear':
        return this.$t('Left rear')
      case 'RightRear':
        return this.$t('Right rear')
      default:
        return '' + placement
    }
  }

  private getTyreHeaderClass(tyre) {
    switch (tyre.rating.toLowerCase()) {
      case 'ok':
        return 'ok'
      case 'marginal':
        return 'warning'
      default:
        return 'critical'
    }
  }

  private openReadingFullImageDialog(tyre) {
    this.fullImageCarouselModel = this.readingImageCarouselModel[tyre.index]
    this.fullImages = this.tyreReadingImages[tyre.index]
    this.openFullImageDialog(tyre)
  }

  private openTyreFullImageDialog(tyre) {
    this.fullImageCarouselModel = this.tyreImageCarouselModel[tyre.index]
    this.fullImages = this.tyreImages[tyre.index]
    this.openFullImageDialog(tyre)
  }

  private openFullImageDialog(tyre) {
    this.fullImageDialog = true
    this.fullImageTyre = tyre
  }

  private setTab(index) {
    this.tab = index
  }

  get readingTimeFormatted() {
    return formatDateTimeString(this.data.readingTime)
  }

  get carDescription() {
    if (!this.data.car) {
      return ''
    }
    const texts = []
    if (this.data.car.brand) {
      texts.push(this.data.car.brand.name)
    }
    if (this.data.car.model) {
      texts.push(this.data.car.model)
    }
    if (this.data.car.year) {
      texts.push(this.data.car.year)
    }
    return texts.join(' ')
  }

  get seasonFormatted() {
    if (!this.data.season) {
      return ''
    }
    return this.$t(this.data.season)
  }
}
